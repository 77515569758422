import React from "react"
import { ThemeProvider as Provider } from "theme-ui"

import mq from "../utils/mq"
import { buttons } from "./buttons"
import { colors } from "./colors"

const Theme = {
  fonts: {
    body: '"Unica77", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Unica77", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  fontWeights: {
    heading: "normal",
  },
  fontSizes: {
    xxs: 10,
    xs: 12,
    sm: 14,
    base: 16,
    md: 18,
    lg: 20,
    xl: 24,
  },
  lineHeights: {
    heading: 1.4,
  },
  space: {
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    11: 44,
    12: 48,
    13: 52,
    14: 56,
    15: 60,
    16: 64,
    17: 68,
    18: 72,
    19: 76,
    20: 80,
    21: 84,
    23: 92,
    28: 112,
    25: 100,
    30: 120,
    45: 180,
  },
  sizes: {
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    11: 44,
    12: 48,
    13: 52,
    14: 56,
    15: 60,
    16: 64,
    17: 68,
    18: 72,
    19: 76,
    20: 80,
    22: 88,
    24: 96,
    25: 100,
    26: 104,
    28: 112,
    29: 116,
    30: 120,
    33: 132,
    35: 140,
    40: 160,
    45: 180,
    53: 212,
    54: 216,
    65: 260,
    75: 300,
    89: 356,
    92: 368,
    100: 400,
    110: 440,
    112: 448,
    119: 476,
    126: 504,
    138: 552,
    147: 588,
    157: 628,
    320: 1280,
  },
  zIndices: {
    productContent: 4000,
    productImageOnHover: 4010,
    modelGenderSwitch: 4020,
    anchorNavigation: 4030,
    categoryBar: 4035,
    mobileMenu: 4040,
    mobileMenuSearchOverlay: 4050,
    mobileMenuSearchInput: 4060,
    header: 9000,
    dismissCatch: 9999,
    productGalleryModal: 10000,
    productDrawer: 10000,
    regionDrawer: 10000,
    cart: 10001,
    shareCart: 10002,
    selectDropdown: 10003,
    notification: 10100,
  },
  radii: {
    xs: 2,
  },
  maxWidth: "1280px",
  breakpointsLegacy: {
    smallMobile: "@media ( min-width: 375px )",
    mobile: "@media ( min-width: 576px )",
    tablet: "@media ( min-width: 768px )",
    desktop: "@media ( min-width: 992px )",
    hd: "@media ( min-width: 1200px )",
  },
  breakpoints: ["768px", "992px", "1440px"],
  margin: {
    y: mq({
      marginTop: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      marginBottom: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    }),
    x: mq({
      marginLeft: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      marginRight: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    }),
  },
  responsive: {
    padding: {
      y: mq({
        paddingTop: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
        paddingBottom: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      }),
      x: mq({
        paddingLeft: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
        paddingRight: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      }),
    },
  },
  typography: {
    minor: ".8rem",
    body: "1rem",
    header: "1.25rem",
    major: "2rem",
  },
  colors,
  buttons,
  borders: {
    muted: "1px solid #4545451A",
    blackTransparent: `1px solid #0000001a`,
    black: "1px solid black",
    grey: "1px solid #E9E9E9",
    dark: "1px solid #454545",
    brownGrey: "1px solid rgba(159, 159, 159, 0.4)",
    danger: "1px solid #EB0000",
  },
  navbar: {
    height: "52px",
    promotional: "76px",
    promoHeight: "30px",
    logoHeight: "18px",
  },
}

export default Theme

export const ThemeProvider = ({ children }) => (
  <Provider theme={Theme}>{children}</Provider>
)
